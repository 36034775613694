<template>
  <b-modal
    id="pendencia-fiscal"
    :ref="'pendencia-fiscal'"
    :title="'Pendência da situação fiscal - ' + empresaNome"
    size="lg"
    no-close-on-backdrop
    no-stacking
    @hidden="fecharModal"
  >
    <template v-slot:modal-footer>
      <div class="w-100">
        <div>        <p
          class="float-left"
          style="max-width: 90%;"
        >
          <feather-icon
            icon="AlertCircleIcon"
            size="16"
            :class="'text-warning'"
          /> Estamos apresentando um resumo da situação fiscal, para consultar as informações completas favor visualizar o relatório da situação fiscal.
          <a
            class="text-primary"
            @click="abrirSituacaoFiscal"
          >
            Clique aqui
          </a>
        </p>
        </div>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="fecharModal"
        >
          OK
        </b-button>
      </div>
    </template>
    <menu-lateral
      :pendencias="gruposExistentes"
      @selecionado="selecionarPendencia"
    />

    <visualizacao
      :pendencias="pendenciasSelecionada"
      :grupo-pendencia="tipoPendenciaSelecionada"
    />
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '../../services/api'

export default {
  name: 'Mensagem',
  components: {
    MenuLateral: () => import('./components/menu-lateral/MenuLateral.vue'),
    Visualizacao: () => import('./components/visualizacao/Visualizacao.vue'),
  },
  props: {
    empresaNome: {
      type: String,
      default: '',
    },
    empresaId: {
      type: Number,
      required: true,
    },
    gruposExistentes: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const toast = useToast()
    const pendencias = ref([])
    const pendenciasSelecionada = ref([])
    const tipoPendenciaSelecionada = ref([])

    const fecharModal = () => {
      context.emit('hidden')
    }

    const abrirSituacaoFiscal = () => {
      context.emit('click-aqui')
    }

    const selecionarPendencia = pendencia => {
      tipoPendenciaSelecionada.value = pendencia
      pendenciasSelecionada.value = pendencias.value.filter(find => find.grupoPendencia.toLowerCase() === pendencia.toLowerCase())
      return pendenciasSelecionada.value
    }

    api.getPendenciasSituacaoFiscal(props.empresaId)
      .then(payload => {
        if (payload.data) {
          pendencias.value = payload.data
          const grupo = pendencias.value[0].grupoPendencia
          selecionarPendencia(grupo)
          context.root.$bvModal.show('pendencia-fiscal')
        }
      })
      .catch(err => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Erro ao abrir detalhamento das pendências',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
        fecharModal()
        throw err
      })

    return {
      abrirSituacaoFiscal,
      fecharModal,
      selecionarPendencia,
      tipoPendenciaSelecionada,
      pendenciasSelecionada,
    }
  },
}

</script>
